import React, { useState, useEffect, useRef } from 'react'
import SkycityProgress from '../Shared/SkycityProgress'

export default function Main () {
    const [iframeError, setIframeError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [progress, setProgress] = useState(0)
    const timeoutRef = useRef(null)

    useEffect(() => {
        let timer
        if (loading) {
            let currentProgress = 0
            timer = setInterval(() => {
                currentProgress += 20
                setProgress(currentProgress)
                if (currentProgress >= 100) {
                    clearInterval(timer)
                }
            }, 1000)

            timeoutRef.current = setTimeout(() => {
                setIframeError(true)
                setLoading(false)
            }, 5000)
        }

        return () => {
            clearInterval(timer)
            clearTimeout(timeoutRef.current)
        }
    }, [loading])

    const handleIframeLoad = () => {
        setIframeError(false)
        setLoading(false)
        setProgress(100)
        clearTimeout(timeoutRef.current)
    }

    return (
        <div className="adobe-skycity-main-pane" style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="adobe-skycity-main">
                <div className="adobe-skycity-main-column" style={{ width: '100%', textAlign: 'center' }}>
                    {iframeError ? (
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '16px' }}>
                                There is an issue displaying the page due to a certificate error.<br />
                                VPN connection is required to access this page.
                            </p>
                            <a
                                href="https://di-art-supermasking.ethos601-prod-va6.ethos.adobe.net"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ fontSize: '16px', color: '#0078D4', textDecoration: 'underline' }}
                            >
                                Click here to view the page in a new tab
                            </a>
                        </div>
                    ) : (
                        <>
                            {loading && (
                                <div>
                                    <SkycityProgress
                                        upprogress={progress}
                                        caption="Loading..."
                                    />
                                </div>
                            )}
                            <iframe
                                src="https://di-art-supermasking.ethos601-prod-va6.ethos.adobe.net"
                                title="Skycity"
                                width="100%"
                                height="100%"
                                onLoad={handleIframeLoad}
                                style={{
                                    display: loading ? 'none' : 'block',
                                    border: 'none'
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
