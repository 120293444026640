/* eslint-disable no-undef, no-restricted-globals, no-unused-expressions, no-sequences, no-use-before-define, new-parens */
import React, { useContext, useEffect } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import Checkbox from '@react/react-spectrum/Checkbox'
import Select from '@react/react-spectrum/Select'
import FieldLabel from '@react/react-spectrum/FieldLabel'
import { error } from '@react/react-spectrum/Toast'

import Sample from '../../lib/ps.js'
import psWasmFile from '../../lib/ps.wasm'
import { getEncodedURI } from '../../lib/skycity.js'

export default function SelectActionByName () {
    const {
        psActionFile,
        psWasm,
        inputSecondFiles,
        dispatch
    } = useContext(SkycityContext)

    const xmlToJSON = function() {
        this.version = "1.3.4";
        var e = {
                mergeCDATA: !0,
                grokAttr: !0,
                grokText: !0,
                normalize: !0,
                xmlns: !0,
                namespaceKey: "_ns",
                textKey: "_text",
                valueKey: "_value",
                attrKey: "_attr",
                cdataKey: "_cdata",
                attrsAsObject: !0,
                stripAttrPrefix: !0,
                stripElemPrefix: !0,
                childrenAsArray: !0
            },
            t = new RegExp(/(?!xmlns)^.*:/),
            r = new RegExp(/^\s+|\s+$/g);
        return this.grokType = function(e) {
            return /^\s*$/.test(e) ? null : /^(?:true|false)$/i.test(e) ? "true" === e.toLowerCase() : isFinite(e) ? parseFloat(e) : e
        }, this.parseString = function(e, t) {
            return this.parseXML(this.stringToXML(e), t)
        }, this.parseXML = function(a, n) {
            for (var s in n) e[s] = n[s];
            var l = {},
                i = 0,
                o = "";
            if (e.xmlns && a.namespaceURI && (l[e.namespaceKey] = a.namespaceURI), a.attributes && a.attributes.length > 0) {
                var c = {};
                for (i; i < a.attributes.length; i++) {
                    var u = a.attributes.item(i);
                    m = {};
                    var p = "";
                    p = e.stripAttrPrefix ? u.name.replace(t, "") : u.name, e.grokAttr ? m[e.valueKey] = this.grokType(u.value.replace(r, "")) : m[e.valueKey] = u.value.replace(r, ""), e.xmlns && u.namespaceURI && (m[e.namespaceKey] = u.namespaceURI), e.attrsAsObject ? c[p] = m : l[e.attrKey + p] = m
                }
                e.attrsAsObject && (l[e.attrKey] = c)
            }
            if (a.hasChildNodes())
                for (var y, d, m, h = 0; h < a.childNodes.length; h++) 4 === (y = a.childNodes.item(h)).nodeType ? e.mergeCDATA ? o += y.nodeValue : l.hasOwnProperty(e.cdataKey) ? (l[e.cdataKey].constructor !== Array && (l[e.cdataKey] = [l[e.cdataKey]]), l[e.cdataKey].push(y.nodeValue)) : e.childrenAsArray ? (l[e.cdataKey] = [], l[e.cdataKey].push(y.nodeValue)) : l[e.cdataKey] = y.nodeValue : 3 === y.nodeType ? o += y.nodeValue : 1 === y.nodeType && (0 === i && (l = {}), d = e.stripElemPrefix ? y.nodeName.replace(t, "") : y.nodeName, m = xmlToJSON.parseXML(y), l.hasOwnProperty(d) ? (l[d].constructor !== Array && (l[d] = [l[d]]), l[d].push(m)) : (e.childrenAsArray ? (l[d] = [], l[d].push(m)) : l[d] = m, i++));
            else o || (e.childrenAsArray ? (l[e.textKey] = [], l[e.textKey].push(null)) : l[e.textKey] = null);
            if (o)
                if (e.grokText) {
                    var x = this.grokType(o.replace(r, ""));
                    null !== x && void 0 !== x && (l[e.textKey] = x)
                } else e.normalize ? l[e.textKey] = o.replace(r, "").replace(/\s+/g, " ") : l[e.textKey] = o.replace(r, "");
            return l
        }, this.xmlToString = function(e) {
            try {
                return e.xml ? e.xml : (new XMLSerializer).serializeToString(e)
            } catch (e) {
                return null
            }
        }, this.stringToXML = function(e) {
            try {
                var t = null;
                return window.DOMParser ? t = (new DOMParser).parseFromString(e, "text/xml") : (t = new window.ActiveXObject("Microsoft.XMLDOM"), t.async = !1, t.loadXML(e), t)
            } catch (e) {
                return null
            }
        }, this
    }.call({});
    "undefined" != typeof module && null !== module && module.exports ? module.exports = xmlToJSON : "function" == typeof define && define.amd && define(function() {
        return xmlToJSON
    });

    useEffect( () => {
        async function getLoad() {
            await loadWasm().then((psWasm) => {
                dispatch({ type: 'set-psWasm', payload: { psWasm } })
            }).catch( e => {
                console.log(e)
                error(`${__('error_msg_title')}: ${e}`, {
                    timeout: 0
                })
            })
        }
        getLoad()
        // eslint-disable-next-line
    },[])

    function loadWasm() {
        return Sample({
            locateFile: () => {
                return psWasmFile;
            }
        });
    }

    function getDefaultActionName (psActionFile) {
        if (psActionFile.actions && psActionFile.actions.length > 0) {
            const items = psActionFile.actions
            const selected = items.find(item => item.selected === true)
            return selected.value
        }
    }

    function changeActionSelection (e) {
        const newActions = psActionFile.actions.map( action => {
            action.selected = false
            if (action.label === e) {
                action.selected = true
            }
            return action
        })
        psActionFile.actions = newActions
        dispatch({ type: 'set-psActionFile', payload: { psActionFile: psActionFile } })
    }

    function changeActionByNameState(e) {
        if (e && inputSecondFiles && inputSecondFiles.length > 0) {
            const newPsActionFile = psActionFile
            newPsActionFile.selected = e
            dispatch({ type: 'set-psActionFile', payload: { psActionFile: newPsActionFile } })
            if (e) {
                getActionNames()
            }
        } else {
            const newPsActionFile = {}
            newPsActionFile.actions = []
            newPsActionFile.selected = e
            dispatch({ type: 'set-psActionFile', payload: { psActionFile: newPsActionFile } })
        }
    }

    async function getActionNames(e) {
        if (inputSecondFiles && inputSecondFiles.length > 0) {
            let FS = psWasm.FS
            const ccAtnFile = `${getEncodedURI(inputSecondFiles[0].path)}`
            const atnFile = await fetch(ccAtnFile);
            const atnBuffer = await atnFile.arrayBuffer();
            let stream = FS.open('/action.atn', 'w');
            var array = new Int8Array(atnBuffer);
            FS.write(stream, array, 0, array.length, 0);
            FS.close(stream);
            setActionNames()
        } else {
            const newPsActionFile = psActionFile
            newPsActionFile.actions = []
            dispatch({ type: 'set-psActionFile', payload: { psActionFile: newPsActionFile } })
        }
    }

    function setActionNames() {
      let Module = psWasm;
      Module.parseActionFile("/action.atn");
      let actionNameList = [];
      let cppOutput = Module.getCppOutput();
      let cppJsonOutput = xmlToJSON.parseString(cppOutput);
      if (cppJsonOutput){
        let actionsFile = cppJsonOutput.Program[0].ActionFile[0];
        for (let i = 0; i < actionsFile.Action.length; i++) {
          actionNameList.push(actionsFile.Action[i].Name[0].UnicodeString[0]._text);
        }
      }
      const newActions = actionNameList.map( action => {
          return {
              label: action,
              value: action,
              selected: false
          }
      })
      newActions[0].selected = true
      psActionFile.actions = newActions
      dispatch({ type: 'set-psActionFile', payload: { psActionFile: psActionFile } })
      Module.resetCppOutput();
    }

    function isDisabled() {
        if (inputSecondFiles && inputSecondFiles.length > 0) {
            return false
        }
        return true
    }

    function isSelected() {
        if (psActionFile && psActionFile.selected) {
            return true
        }
        return false
    }

    return <div className="adobe-skycity-flex-row">
        <Checkbox value={ isSelected() }
            id="cbPsActionFile"
            disabled={ isDisabled() }
            onChange={ e => changeActionByNameState(e)}></Checkbox>
        <FieldLabel label="Play Action by Name" labelFor="cbPsActionFile" position="left">
            <Select
                disabled={!psActionFile.selected}
                options={psActionFile.actions}
                onChange={e => changeActionSelection(e)}
                value={ getDefaultActionName(psActionFile) } />
        </FieldLabel>
    </div>
}
