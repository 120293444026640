import React, { useContext, useState } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'
import SenseiParams from './SenseiParams'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import { ssApiCall, getSenseiStatus } from '../../lib/ccApi.js'
import {
    getFilename,
    getFilenameWithoutExt,
    getEncodedURI,
    getTempDirectory,
    getSenseiHeaders,
    checkFileType
} from '../../lib/skycity.js'

import {
    retrieveRendition,
    retrievePreSignedDownloadUrl
} from '../../lib/acpApi.js'

import axios from 'axios'

const uuid = require('uuid/v4')

export default function Operation (files) {
    const {
        inputFiles,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    const [params, setParams] = useState({
        outputForm: [
            { label: '8 bit Grayscale', value: '8bit', selected: true },
            { label: 'RGB', value: 'color', selected: false },
            { label: '16 bit Grayscale', value: '16bit', selected: false }
        ],
        outputMode: [
            { label: 'Diparity', value: 'disp', selected: true },
            { label: 'Depth', value: 'depth', selected: false },
            { label: 'Log Depth', value: 'log_depth', selected: false }
        ],
        inputDim: 640,
        refineDim: 1280,
        refine: true
    })

    function formatParams (rawParams) {
        const form = rawParams.outputForm.find((mode) => mode.selected).value
        const mode = rawParams.outputMode.find((form) => form.selected).value
        const refine = rawParams.refine
        const inputDim = Number.parseInt(rawParams.inputDim)
        const refineDim = refine ? Number.parseInt(rawParams.refineDim) : null
        return {
            output_form: form,
            mode: mode,
            refine: refine,
            inputDim: inputDim,
            refineDim: refineDim
        }
    }

    async function start (curFile) {
        const formattedParams = formatParams(params)
        // replaced with RAPI
        const tempURN = curFile.tempUrn
        const assetURN = curFile.curFileUrn
        const renditionPath = curFile.renditionPath
        const outputFileName = `depthestimation_${uuid()}_${curFile.shortName}`
        const codePayload = {
            contentAnalyzerRequests: {
                'sensei:name': 'DepthEstimation',
                'sensei:invocation_mode': 'asynchronous',
                'sensei:invocation_batch': false,
                'sensei:in_response': false,
                'sensei:engines': [
                    {
                        'sensei:execution_info': {
                            'sensei:engine': 'Feature:depth-prediction:Service-227829920d814c60bdd52d29deb72594'
                        },
                        'sensei:params': formattedParams,
                        'sensei:inputs': {
                            image_in: {
                                'dc:format': checkFileType(curFile.type),
                                'sensei:repoType': 'RAPI',
                                'repo:id': assetURN
                            }
                        },
                        'sensei:outputs': {
                            image_out: {
                                'repo:id': tempURN,
                                'dc:format': 'image/png',
                                'sensei:repoType': 'RAPI',
                                'repo:resource': {
                                    reltype: 'http://ns.adobe.com/adobecloud/rel/create',
                                    path: `${outputFileName}.png`
                                }
                            },
                            metadata_out: {
                                'repo:id': tempURN,
                                'dc:format': 'application/json',
                                'sensei:repoType': 'RAPI',
                                'repo:resource': {
                                    reltype: 'http://ns.adobe.com/adobecloud/rel/create',
                                    path: `${outputFileName}.json`
                                }
                            }
                        }
                    }
                ]
            }
        }

        const formData = new FormData()
        formData.append('contentAnalyzerRequests', JSON.stringify(codePayload.contentAnalyzerRequests))
        const config = {
            file: curFile,
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_MLSENSEI}/predict`,
            method: 'POST',
            mode: 'cors',
            timeout: 90000,
            headers: getSenseiHeaders(),
            payload: codePayload,
            body: {
                formData: formData
            }
        }

        /* *********************************** */
        dispatch({ type: 'set-codes', payload: { config: config } })

        const depthEstimationResponse = await ssApiCall(config)
        if (depthEstimationResponse && depthEstimationResponse.status < 400) {
            const senseiStatusResponse = await getSenseiStatus(depthEstimationResponse.getResponseHeader('location'))
            if (senseiStatusResponse && senseiStatusResponse.status === '200') {
                const outputHref = `${getTempDirectory()}/${outputFileName}.png`
                const outputJsonHref = `${getTempDirectory()}/${outputFileName}.json`
                const outputJsonURL = `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputJsonHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}`;
                const headers = {
                    "Authorization": `Bearer ${window.adobeIMS.getAccessToken().token}`
                };
                const outputJsonResponse = await axios.get(outputJsonURL, { headers });

                if (outputJsonResponse && outputJsonResponse.status === 200) {
                    // Skycity library to be udpated for Sensei response
                    if (files.find(file => file.inputFile === curFile)) {
                        return
                    }
                    const response = { ...senseiStatusResponse, ...outputJsonResponse.data }

                    const largeRendition = await retrieveRendition(
                        `${renditionPath}/${getEncodedURI(outputHref)}`,
                        300,
                        'image/jpeg'
                    )
                    const defaultRendition = await retrieveRendition(
                        `${renditionPath}/${getEncodedURI(outputHref)}`,
                        0,
                        'image/jpeg'
                    )
                    const preSignedDownloadUrl = await retrievePreSignedDownloadUrl(`${curFile.url}/${getEncodedURI(outputHref)}`)

                    files.push({
                        inputFile: curFile,
                        name: getFilename(outputHref),
                        shortName: getFilenameWithoutExt(outputHref),
                        path: outputHref,
                        outputThumbnail: largeRendition,
                        output: defaultRendition,
                        outputToDownload: preSignedDownloadUrl
                    })
                    dispatch({
                        type: 'sensei-success',
                        payload: {
                            files: files,
                            response: response
                        }
                    })
                }
            } else {
                const errMsg = senseiStatusResponse.errors ? `${JSON.stringify(senseiStatusResponse.errors, null, 2)}` : `ERROR with ${config.url}`
                errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, senseiStatusResponse)
            }
        } else {
            const errMsg = depthEstimationResponse ? `${JSON.stringify(depthEstimationResponse.response, null, 2)}` : `ERROR with ${config.url}`
            errorOccured(`${errMsg}`, depthEstimationResponse)
        }
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(inputFile => {
                funcArray.push(start(inputFile))
            })

            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    console.log(`err: ${err}`)
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                    dispatch({
                        type: 'sensei-end-wait'
                    })
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <SenseiParams params={params} setParams={setParams} />
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
