import React, { useContext } from 'react'
import { SideNav, SideNavItem } from '@react/react-spectrum/SideNav'
import { SkycityContext } from '../../lib/SkycityProvider'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
// import { info } from '@react/react-spectrum/Toast'
import { goToUrlTarget, getToken } from '../../lib/skycity.js'

export default function SkycityNav () {
    const { page, dispatch } = useContext(SkycityContext)
    // async function checkVpnConnection () {
    //     try {
    //         const response = await fetch('https://kmikawa-test.adobedice.com/metacaf/index.html', {
    //             method: 'HEAD'
    //         })
    //         return response.ok
    //     } catch (error) {
    //         if (error.message.includes('Failed to fetch') || error.message.includes('NetworkError')) {
    //             return false
    //         }
    //         return false
    //     }
    // }

    async function goToFeaturePage (page) {
        // if (page === 'ssmetacafmodelcompare') {
        //     const isVpnConnected = await checkVpnConnection()
        //     if (!isVpnConnected) {
        //         info('VPN is required to view Model Comparison page. Please connect to the VPN.', {
        //             timeout: 0
        //         })
        //         return
        //     }
        // }
        switch (page) {
        case 'psapi':
        case 'lrapi':
        case 'senseiapi':
        case 'pocapi':
        case 'links':
        case 'banneradreflow':
        case 'tool':
        case 'oldskycity':
        case 'easybake':
        case 'pegasus':
        case 'prerelease':
        case 'prerelease_onboarding':
        case 'prerelease_signup':
        case 'prerelease_token':
            break
        default:
            dispatch({ type: 'set-page', payload: { page } })
            window.history.replaceState(null, null, `?ref=${page}`)
        }
    }
    function getPocMenu () {
        if (process.env.REACT_APP_CONFIG_APP_DEV === 'stage') {
            return <SideNavItem value="pocapi" label={__('poc_api_title')}>
                {/* <SideNavItem className="adobe-skycity-side-nav-item" value="ssgeneration" label={__('poc_bag_nav_title')} /> */}
                {/* <SideNavItem value="pegasus" label={__('poc_pegasus_nav_title')}>
                    <SideNavItem className="adobe-skycity-side-nav-item" value="psactions" label={__('poc_psactions_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="pegasus_api" label={__('poc_pegasus_api1_nav_title')} disabled/>
                </SideNavItem> */}
                <SideNavItem className="adobe-skycity-side-nav-item" value="pocloc" label={__('poc_loc_nav_title')} />
                <SideNavItem className="adobe-skycity-side-nav-item" value="psuxp" label={__('poc_psuxp_nav_title')} />
                <SideNavItem className="adobe-skycity-side-nav-item" value="ssswappingautoencoder" label={__('ssswappingautoencoder_nav_title')} />
                <SideNavItem value="banneradreflow" label={__('psreflow_nav_title')}>
                    <SideNavItem className="adobe-skycity-side-nav-item" value="psreflowcep" label={__('psreflowcep_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="psreflowweb" label={__('psreflowweb_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssautotag" label={__('ssautotag_nav_title')} />
                    {/* <SideNavItem className="adobe-skycity-side-nav-item" value="ssreflow" label={__('psreflow_nav_title')} href={process.env.REACT_APP_CONFIG_URL_EASYBAKE_REFLOW} target="_blank" /> */}
                </SideNavItem>
                <SideNavItem className="adobe-skycity-side-nav-item" value="template" label={__('poc_template_nav_title')} />
            </SideNavItem>
        } else {
            // return <SideNavItem value="pocapi" label={__('poc_api_title')}>
            //     <SideNavItem className="adobe-skycity-side-nav-item" value="pssmartobjectCsv" label={__('pssmartobjectCsv_nav_title')} />
            // </SideNavItem>
            return <SideNavItem></SideNavItem>
        }
    }
    return (
        <div className="">
            <SideNav variant="multiLevel" onSelect={page => goToFeaturePage(page)} defaultValue={page}>
                <SideNavItem value="psapi" label={__('ps_api_title')}>
                    <SideNavItem value="psdocumentmanifest" label={__('get_document_manifest_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="psedit" label={__('psedit_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="psinsertlayer" label={__('psinsertlayer_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="psfileconversion" label={__('psfileconversion_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="pssmartobject" label={__('pssmartobject_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="pssmartobjectV2" label={__('pssmartobjectV2_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="psartboard" label={__('psartboard_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="psactions" label={__('poc_psactions_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="pstext" label={__('pstext_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="psproductcrop" label={__('psproductcrop_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="pssocialmediarendition" label={__('pssocialmediarendition_nav_title')} />
                </SideNavItem>
                <SideNavItem value="lrapi" label={__('lr_api_title')}>
                    <SideNavItem className="adobe-skycity-side-nav-item" value="lrautostraighten" label={__('lrautostraighten_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="lrautotone" label={__('lrautotone_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="lrmasking" label={__('lrmasking_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="lrmaskingpredict" label={__('lrmaskingpredict_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="lrapplyedit" label={__('lrapplyedit_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="lrapplypreset" label={__('lrapplypreset_nav_title')} />
                </SideNavItem>
                <SideNavItem value="senseiapi" label={__('sensei_api_title')}>
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssimagecutout" label={__('ssimagecutout_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssimagecutoutonsensei" label={__('ssimagecutoutonsensei_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssimagecutoutonsenseiasync" label={__('ssimagecutoutonsenseiasync_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssimagecutoutv5" label={__('ssimagecutoutv5_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssbodyparser" label={__('ssbodyparser_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="sscmganholefilling" label={__('sscmganholefilling_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssdepthestimation" label={__('ssdepthestimation_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="sstrimapmatting" label={__('sstrimapmatting_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssmaskrefinement" label={__('ssmaskrefinement_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssinteractiveselection" label={__('ssinteractiveselection_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssobjectselection" label={__('ssobjectselection_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssdomo" label={__('ssdomo_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="sslayerscene" label={__('sslayerscene_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssshadowdetection" label={__('ssshadowdetection_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssshadowremoval" label={__('ssshadowremoval_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssshadowgenerator" label={__('ssshadowgenerator_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssuniversalrefinement" label={__('ssuniversalrefinement_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssselectall" label={__('ssselectall_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssmetacaf" label={__('ssmetacaf_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssadaptiveprofiles" label={__('ssadaptiveprofiles_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssentitysegmentation" label={__('ssentitysegmentation_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="sssegmentanything" label={__('sssegmentanything_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssstuffsegmentation" label={__('ssstuffsegmentation_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssparametricharmonization" label={__('ssparametricharmonization_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssllava" label={__('ssllava_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssgroundingdino" label={__('ssgroundingdino_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssacrmasking" label={__('ssacrmasking_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssdistractorice" label={__('ssdistractorice_nav_title')} />
                    {/*
                    <SideNavItem className="adobe-skycity-side-nav-item" value="sspieonsensei" label={__('sspieonsensei_nav_title')} />
                    */}
                </SideNavItem>
                {/* <SideNavItem value="links" label={__('links_title')}>
                    <SideNavItem className="adobe-skycity-side-nav-item" value="prerelease" label={__('prerelease_link_title')} onClick={() => { goToUrlTarget(__('prerelease_link'), '_blank') }} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="prerelease_onboarding" label={__('onboarding_link_title')} onClick={() => { goToUrlTarget(__('onboarding_link_internal'), '_blank') }} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="prerelease_onboarding" label={__('poc_onboarding_title')} onClick={() => { goToUrlTarget(__('onboarding_link_external'), '_blank') }} />
                </SideNavItem> */}
                <SideNavItem value="tool" label={__('tool_title')}>
                    {/* <SideNavItem className="adobe-skycity-side-nav-item" value="toolhealthcheck" label={__('tool_nav_title_hc')} /> */}
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssdiartsupermaskingtool" label={__('ssdiartsupermaskingtool_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="ssmetacafmodelcompare" label={__('ssmetacafmodelcompare_nav_title')} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="prerelease_token" label={__('get_token_link_title')} onClick={(e) => { getToken(e) }} />
                    <SideNavItem className="adobe-skycity-side-nav-item" value="diartsportfolio" label={__('diarts_portfolio_title')} onClick={() => { goToUrlTarget(__('diarts_portfolio_link'), '_blank') }} />
                </SideNavItem>
                {getPocMenu()}
            </SideNav>
        </div>
    )
}
