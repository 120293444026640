import React, { createContext, useContext } from 'react'
import { SkycityContext } from '../../lib/SkycityProvider'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import AWS from 'aws-sdk'

import DropZone from '@react/react-spectrum/DropZone'
import DropdownButton from '@react/react-spectrum/DropdownButton'
import { MenuItem } from '@react/react-spectrum/Menu'
import { Asset } from '@react/react-spectrum/Asset'
import Button from '@react/react-spectrum/Button'
import Heading from '@react/react-spectrum/Heading'
import ModalTrigger from '@react/react-spectrum/ModalTrigger'
import Dialog from '@react/react-spectrum/Dialog'
import Delete from '@react/react-spectrum/Icon/Delete'
import { Card, CardBody, CardPreview, CardFooter } from '@react/react-spectrum/Card'
import SkycityProgress from '../Shared/SkycityProgress'
import IllustratedMessage from '@react/react-spectrum/IllustratedMessage'
import UploadToCloud from '@react/react-spectrum/Icon/UploadToCloud'
import CreativeCloud from '@react/react-spectrum/Icon/CreativeCloud'
import Cloud from '@react/react-spectrum/Icon/Cloud'

import { error, warning } from '@react/react-spectrum/Toast'

import CcBrowser from '../Shared/CcBrowser'
import CloudContentBrowser from '../Shared/CloudContentBrowser'
import S3Browser from '../Shared/S3Browser'
import StockBrowser from '../Shared/StockBrowser'
import SortableContent from '../Shared/SortableContent'

import AdobeStockLogo from '../../asset/stock_logo_b.png'
import AwsS3Logo from '../../asset/aws_s3_logo.png'
import AtnLogo from '../../asset/atn.png'

import filesize from 'filesize'
import axios from 'axios'
import {
    getCcFiles,
    getCloudContentFiles,
    getStockFiles,
    getFilenameWithoutExt,
    getDocumentManifest,
    getRenditionCreateWithUtilsStorage,
    getLocalStorageItem,
    setLocalStorageItem,
    getContentType,
    getExtensionFromPath,
    getRenditionCreate,
    getCCStorageErrorMsg,
    isSupportedFileForThumnail
} from '../../lib/skycity.js'

import {
    generatePresignedUrlsFromUtils
} from '../../lib/utilsServiceApi'

import {
    createDirectory,
    exploreHomeDirectory,
    exploreIndexDocument,
    retrieveRendition
} from '../../lib/acpApi'
const uriTemplateParser = require('uri-template')
const LinkHeader = require('http-link-header')

export default function SelectInput ({
    actionType,
    title,
    storage,
    imageTypes,
    inputType,
    multiple,
    files,
    file,
    pFiles,
    documentManifest
}) {
    const {
        dispatch,
        wait,
        page,
        selectedInputFile,
        showCcBrowserPane,
        showCcBrowserPaneFont,
        showCcBrowserPaneSecond,
        showCcBrowserPaneIcc,
        showCloudContentBrowserPane,
        showCloudContentBrowserPaneSecond,
        showCloudContentBrowserPaneIcc,
        showS3BrowserPane,
        showS3BrowserPaneSecond,
        showS3BrowserPaneFont,
        showS3BrowserPaneIcc,
        showAsBrowserPane,
        showAsBrowserPaneSecond,
        showAsBrowserPaneIcc,
        previewEdit
    } = useContext(SkycityContext)

    if (!files) {
        files = []
    }
    if (!pFiles) {
        pFiles = []
    }

    const SelectInputContext = createContext()
    const SelectInputProvider = ({ children }) => {
        return (
            <SelectInputContext.Provider>
                {children}
            </SelectInputContext.Provider>
        )
    }

    function getImageTypes () {
        // return imageTypes ? imageTypes : "image/*";
        return imageTypes || 'image/vnd.adobe.photoshop'
    }

    function upload (e) {
        document.getElementById(`fileUpload-${actionType}`).click()
    }

    function browseCc () {
        getCcFiles(null, (ccFiles, res) => {
            if (ccFiles && ccFiles.total_children > 0) {
                // TODO kmikawa debug
                ccFiles.children.map(file => {
                    if (file.type !== 'application/vnd.adobe.directory+json') {
                        const ext = getExtensionFromPath(file.path).toLowerCase()
                        if (ext === 'psd' || ext === 'psb') {
                            console.log(`${ext}\t\t| ${file.type} | ${file.path}`)
                        }
                    }
                })

                dispatch({ type: 'set-ccfiles', payload: { ccFiles: ccFiles } })
                dispatch({ type: 'toggle-cc-browser-pane', payload: { actionType: actionType, inputType: inputType, visible: true } })
            } else {
                const msg = getCCStorageErrorMsg(res)
                console.log(msg)
            }
        })
    }

    function browseCloudContent () {
        getCloudContentFiles(null, (cloudContentFiles, res) => {
            if (cloudContentFiles && cloudContentFiles.total_children > 0) {
                dispatch({ type: 'set-cloudContentFiles', payload: { cloudContentFiles: cloudContentFiles } })
                dispatch({ type: 'toggle-cloudContent-browser-pane', payload: { actionType: actionType, visible: true } })
            } else {
                const msg = `cloudContentFiles not found - ${JSON.stringify(res)}`
                console.log(msg)
                alert(msg)
            }
        })
    }

    function browseStock () {
        // const params = 'Rest/Media/1/Search/Files?locale=en_US%26search_parameters%5Bwords%5D=kittens';
        const params = 'Rest/Media/1/Search/Files'
        const locale = 'en_US'
        const keywords = getLocalStorageItem('asSearchKeyword') || setLocalStorageItem('asSearchKeyword', __('browser_as_search_string'))
        getStockFiles(params, locale, keywords, (asFiles, res) => {
            if (asFiles && asFiles.files.length > 0) {
                dispatch({ type: 'set-asfiles', payload: { asFiles: asFiles.files } })
                dispatch({ type: 'toggle-as-browser-pane', payload: { actionType: actionType, visible: true } })
            } else {
                const msg = `asFiles not found - ${JSON.stringify(res)}`
                console.log(msg)
                alert(msg)
            }
        })
    }

    function getDirectoryToSearch () {
        switch (imageTypes) {
        case '.icc,text/plain,text/csv,application/vnd.iccprofile':
            return 'icc'
        default:
            return page
        }
    }

    function browseS3 () {
        dispatch({ type: 'set-appProgress', payload: { appProgress: true, appProgressMsg: __('aws_loading_message') } })
        AWS.config.region = process.env.REACT_APP_CONFIG_TEST_AWS_REGION
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.REACT_APP_CONFIG_TEST_AWS_IDENTITYPOOLID
        })
        const s3 = new AWS.S3({
            apiVersion: '2006-03-01',
            params: { Bucket: process.env.REACT_APP_CONFIG_TEST_AWS_S3_BUCKET }
        })
        let res;
        (async () => {
            const s3Assets = []
            for (let continuationToken = null; ;) {
                const params = {
                    Bucket: process.env.REACT_APP_CONFIG_TEST_AWS_S3_BUCKET
                }
                if (continuationToken) {
                    params.ContinuationToken = continuationToken
                }
                res = await s3.listObjectsV2(params).promise()
                const featureFiles = res.Contents.filter(content => {
                    const filePath = `${process.env.REACT_APP_CONFIG_URL_SKYCITY_ASSET}/${content.Key}`
                    const contentType = getContentType(getExtensionFromPath(filePath))
                    const directoryToSearch = getDirectoryToSearch()
                    const regexPath = `input/${directoryToSearch}`
                    const regex = new RegExp(regexPath, 'g')
                    if (content.Key.match(regex) && contentType) {
                        return content.Key
                    }
                    return null
                })
                if (featureFiles.length > 0) {
                    featureFiles.forEach(file => {
                        const filePath = `${process.env.REACT_APP_CONFIG_URL_SKYCITY_ASSET}/${file.Key}`
                        const contentType = getContentType(getExtensionFromPath(file.Key))
                        const fileSize = file.Size
                        getRenditionCreate({ url: filePath, type: contentType, storage: 'external' }, (err, renditionSmall, renditionLarge) => {
                            if (err) {
                                console.log(`ERROR: ${JSON.stringify(err)}`)
                                error(`ERROR: ${JSON.stringify(err)}`, {
                                    timeout: 0
                                })
                            } else {
                                s3Assets.push({
                                    key: file.Key,
                                    thumbnail_url: filePath,
                                    size: fileSize,
                                    shortName: getFilenameWithoutExt(file.Key),
                                    name: getFilenameWithoutExt(file.Key),
                                    content_type: getContentType(getExtensionFromPath(file.Key)),
                                    thumbnail_url_l: renditionLarge,
                                    thumbnail_url_s: renditionSmall
                                })
                                dispatch({ type: 'set-s3files', payload: { s3Files: s3Assets } })
                            }
                            dispatch({ type: 'set-appProgress', payload: { appProgress: false, appProgressMsg: null } })
                        })
                    })
                } else {
                    const msg = `File Not Found in AWS S3 for ${getDirectoryToSearch()}`
                    warning(msg, {
                        timeout: 0
                    })
                    dispatch({ type: 'set-appProgress', payload: { appProgress: false, appProgressMsg: null } })
                }

                if (!res.IsTruncated) {
                    break
                }
                continuationToken = res.NextContinuationToken
            }
            dispatch({ type: 'toggle-s3-browser-pane', payload: { actionType: actionType, visible: true } })
        })()
    }

    function deleteAll (e) {
        dispatch({ type: actionType, payload: { file: [] } })
        if (actionType === 'set-input') {
            dispatch({ type: 'set-selected-layers', payload: { layers: [] } })
            dispatch({ type: 'clear-output', payload: { } })
        }
        if (actionType === 'set-input-second') {
            const newPsActionFile = {}
            newPsActionFile.selected = false
            newPsActionFile.actions = []
            dispatch({ type: 'set-psActionFile', payload: { psActionFile: newPsActionFile } })
        }
    }

    function clickUpload (e) {
        e.preventDefault()
        e.stopPropagation()
        const funcArray = []
        const curUrl = new URL(document.location.href)
        const refComponent = curUrl.searchParams.get('ref')

        let fileList = null
        if (e.target && e.target.files) {
            fileList = e.target.files
        } else {
            if (e.dataTransfer.files.length > 1) {
                return
            }
            fileList = e.dataTransfer.files
        }

        for (var x = 0; x < fileList.length; x++) {
            if (refComponent === 'sscmganholefilling' || refComponent === 'ssdepthestimation' || refComponent === 'ssshadowgenerator') {
                funcArray.push(uploadFileToDirectory(fileList[x]))
            } else {
                funcArray.push(fileUploadToUtilsService(fileList[x]))
            }
        }
        axios.all(funcArray)
            .then((filesToUpload) => {
                filesToUpload.forEach(file => {
                    let curFile
                    if (refComponent === 'sscmganholefilling' || refComponent === 'ssdepthestimation' || refComponent === 'ssshadowgenerator') {
                        curFile = {
                            name: file.name,
                            storage: 'adobe',
                            shortName: getFilenameWithoutExt(file.name),
                            type: file.name.split('.').pop(),
                            size: file.size,
                            path: file.path,
                            url: file.url,
                            largeRenditionGet: file.largeRenditionGet,
                            smallRenditionGet: file.smallRenditionGet,
                            tempUrn: file.tempUrn,
                            curFileUrn: file.curFileUrn,
                            renditionPath: file.renditionPath
                        }
                    } else {
                        curFile = {
                            name: file.name,
                            storage: 'external',
                            path: file.path,
                            type: file.name.split('.').pop(),
                            size: file.size,
                            shortName: getFilenameWithoutExt(file.name),
                            largeRenditionGet: file.largeRenditionGet,
                            smallRenditionGet: file.smallRenditionGet
                        }
                    }
                    if (documentManifest) {
                        getDocumentManifest(curFile, files => {
                            if (files) {
                                dispatch({ type: actionType, payload: { wait: false, files: files, file: { progress: 0, name: '' } } })
                                if (!selectedInputFile) {
                                    if (actionType === 'set-input') {
                                        dispatch({ type: 'set-selected-input', payload: { file: files[0] } })
                                        dispatch({ type: 'toggle-layer-info-pane', payload: { showLayerInfoPane: true } })
                                    }
                                }
                            }
                        })
                    } else {
                        files.push(curFile)
                        dispatch({ type: actionType, payload: { wait: false, files: files, file: { progress: 0, name: '' } } })
                    }
                })
            })
            .catch((err) => {
                console.log(`ERROR: ${JSON.stringify(err)}`)
                error(`ERROR: ${JSON.stringify(err)}`, {
                    timeout: 0
                })
            })
            .finally(() => {
                dispatch({ type: actionType, payload: { wait: false, files: files, file: { progress: 0, name: '' } } })
            })
    }

    function onDrop (e) {
        e.preventDefault()
        // var data = e.dataTransfer.getData("text");
        // if (data !== '') {
        //     var src = e.dataTransfer.getData("src");
        //     var alt = e.dataTransfer.getData("alt");
        //     const file = {
        //         path: data,
        //         name: alt,
        //         src: src
        //     }
        //     addfiles(file);
        // }
    }

    function onDragOver (e) {
        e.preventDefault()
    }

    async function uploadFileToDirectory (fileToUpload) {
        // 1. Explore the Index Document and get the Primary URL of the home Directory
        const homeDirectoryPrimaryUrl = await exploreIndexDocument()

        // 2. Explore the home Directory and get the Create URL of the Cloud Documents Directory
        const exploreHomeDirectoryResponse = await exploreHomeDirectory(
            homeDirectoryPrimaryUrl
        )
        const cloudDocumentsCreateUrl = exploreHomeDirectoryResponse.createLink

        // 3. Create a Directory within the Cloud Documents Directory and return the Directory's links
        const links = await createDirectory(cloudDocumentsCreateUrl)
        const directoryCreateUrl = links.refs.filter(val => val.rel.match('http://ns.adobe.com/adobecloud/rel/create'))[0].uri
        // 4. Upload a File to the new Directory
        dispatch({ type: actionType, payload: { wait: true, files: files, file: { progress: 0, name: fileToUpload.name } } })
        const fileUploaded = await uploadFile(
            directoryCreateUrl,
            fileToUpload
        )
        const ext = fileToUpload.name.split('.').pop()
        const uploadlinks = LinkHeader.parse(fileUploaded.headers.get('link'))
        const filePrimaryUrl = uploadlinks.refs.filter(val => val.rel.match('http://ns.adobe.com/adobecloud/rel/primary'))[0].uri
        const renditionUrl = uploadlinks.refs.filter(val => val.rel.match('http://ns.adobe.com/adobecloud/rel/rendition'))[0].uri
        // 5. GET rendition of the file uplaoded with size 0 and 300
        const largeRendition = await retrieveRendition(
            renditionUrl,
            300,
            getContentType(ext)
        )
        const smallRendition = await retrieveRendition(
            renditionUrl,
            0,
            getContentType(ext)
        )
        const config = {
            name: fileToUpload.name,
            url: filePrimaryUrl.split('/temp')[0],
            type: fileToUpload.type || ext,
            size: fileToUpload.size,
            path: '/temp' + decodeURIComponent(fileUploaded.url.split('/temp')[1].replace(':create?path=', '')),
            smallRenditionGet: smallRendition,
            largeRenditionGet: largeRendition,
            tempUrn: exploreHomeDirectoryResponse.tempUrn,
            curFileUrn: fileUploaded.headers.get('Asset-ID'),
            renditionPath: renditionUrl.split('/temp')[0]
        }
        return config
    }
    async function uploadFile (createUrl, fileToUpload) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onprogress = function (e) {
                const currentProgress = (parseInt(e.loaded) / parseInt(e.total) * 100).toFixed(0)
                const curFile = {}
                curFile.name = fileToUpload.name
                curFile.progress = currentProgress
                dispatch({ type: actionType, payload: { wait: true, files: files, file: { progress: currentProgress, name: fileToUpload.name } } })
            }
            reader.onload = async function (event) {
                try {
                    const binaryData = event.target.result

                    const tpl = uriTemplateParser.parse(createUrl)
                    const url = tpl.expand({ path: fileToUpload.name })
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Cache-Control': 'no-cache',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${window.adobeIMS.getAccessToken().token}`,
                            'X-api-key': `${process.env.REACT_APP_CONFIG_APP_KEY}`,
                            'Content-Type': getContentType(fileToUpload.name.split('.').pop())
                        },
                        body: binaryData
                    })
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`)
                    }
                    resolve(response)
                } catch (error) {
                    reject(error)
                }
            }
            reader.onerror = function () {
                reject(new Error('Failed to read the file'))
            }
            reader.readAsArrayBuffer(fileToUpload)
        })
    }
    async function fileUploadToUtilsService (fileToUpload) {
        return new Promise((resolve, reject) => {
            const config = {
                name: fileToUpload.name,
                path: '',
                type: fileToUpload.type,
                size: fileToUpload.size
            }
            async function processUpload () {
                try {
                    const response = await generatePresignedUrlsFromUtils(1)
                    dispatch({ type: actionType, payload: { wait: true, files: files, file: { progress: 0, name: fileToUpload.name } } })
                    const configS3PUT = {
                        headers: {
                            'Content-Type': 'application/octet-stream',
                            'Access-Control-Allow-Origin': '*'
                        },
                        onUploadProgress: (e) => {
                            const currentProgress = (parseInt(e.loaded) / parseInt(e.total) * 100).toFixed(0)
                            const curFile = {}
                            curFile.name = fileToUpload.name
                            curFile.progress = currentProgress
                            dispatch({ type: actionType, payload: { wait: true, files: files, file: { progress: currentProgress, name: fileToUpload.name } } })
                        },
                        timeout: 300000
                    }
                    await axios.put(response.data.outputs.urls[0].put, fileToUpload, configS3PUT).then(async (res, err) => {
                        if (!err) {
                            config.path = response.data.outputs.urls[0].get
                            config.storage = 'external'
                            config.type = config.name.split('.').pop()
                            if (isSupportedFileForThumnail(config.type.toLowerCase())) {
                                await getRenditionCreateWithUtilsStorage(config, 300, 0, (err, renditionSmall, renditionLarge) => {
                                    if (err) {
                                        console.log(`ERROR: ${JSON.stringify(err)}`)
                                    } else {
                                        config.smallRenditionGet = renditionSmall
                                        config.largeRenditionGet = renditionLarge
                                        resolve(config)
                                    }
                                })
                            } else {
                                resolve(config)
                            }
                        } else {
                            reject(err)
                        }
                    })
                } catch (error) {
                    reject(error)
                }
            }
            processUpload()
        })
    }

    function getFileSize (num) {
        if (num) {
            return filesize(num, { round: 0 })
        } else {
            return ''
        }
    }

    function removeFile (e, path) {
        for (let i = 0; i < files.length; i++) {
            if (files[i].path === path) {
                files.splice(i, 1)
                i--
            }
        }
        if (files.length === 0) {
            deleteAll()
        } else {
            dispatch({ type: actionType, payload: { wait: false, files: files, file: { progress: 0, name: '' } } })
            dispatch({
                type: 'set-input-preview',
                payload: {
                    files: files,
                    loading: false,
                    wait: false,
                    response: [],
                    layers: []
                }
            })
            dispatch({ type: 'clear-output', payload: { } })
        }
    }

    function getDocInfo (file) {
        if (file && file.documentManifest && file.documentManifest.document) {
            return <div>
                <div>{`width: ${file.documentManifest.document.width}px`}</div>
                <div>{`height: ${file.documentManifest.document.height}px`}</div>
                <div>{`imageMode: ${file.documentManifest.document.imageMode}`}</div>
                <div>{`bitDepth: ${file.documentManifest.document.bitDepth}`}</div>
                <div>{`photoshopBuild: ${file.documentManifest.document.photoshopBuild}`}</div>
            </div>
        }
        return null
    }

    function getPsdAssetThumbnail (file, size) {
        if (file.s3_id) {
            return <Asset
                type="image"
                cache={true}
                src={file.thumbnail_url_s} />
        }
        if (size === 'small') {
            return <Asset
                type="image"
                cache={true}
                src={file.thumbnail_url_s} />
        }
        return <Asset
            className="adobe-skycity-cursor-pointer"
            type="image"
            cache={true}
            src={file.thumbnail_url_l} />
    }

    function getCardFooter (file) {
        return <CardFooter><div className="adobe-skycity-flex-row-space-between">{file.storage}</div></CardFooter>
    }
    function getCardBody (file) {
        return <CardBody title={file.name} subtitle={getFileType(file)} description={getFileSize(file.size)} />
    }

    function getThumbnail (files, type) {
        const cards = files.map(file => {
            if ((file.s3_id && file.content_type === 'text/plain') || (file.s3_id && file.content_type === 'text/csv')) {
                return <div className="adobe-skycity-asset-card" key={file.path}>
                    <Card
                        allowsSelection={false}
                        key={file.path} >
                        { getCardBody(file) }
                        { getCardFooter(file) }
                    </Card>
                </div>
            } else if (file.type === 'application/octet-stream') {
                return <div className="adobe-skycity-asset-card" key={file.path}>
                    <Card
                        allowsSelection={false}
                        key={file.path} >
                        <Asset
                            className=""
                            type="image"
                            cache={true}
                            src={AtnLogo} />
                        { getCardBody(file) }
                        { getCardFooter(file) }
                    </Card>
                </div>
            } else if (file.type === 'application/vnd.adobe.directory+json') {
                return <div className="adobe-skycity-asset-card" key={file.path}>
                    <Card
                        allowsSelection={false}
                        key={file.path} >
                        <CardPreview>
                            <Asset type="folder" />
                        </CardPreview>
                        { getCardBody(file) }
                        { getCardFooter(file) }
                    </Card>
                </div>
            } else if (file.s3_id && file.content_type === 'image/vnd.adobe.photoshop') {
                return <div className="adobe-skycity-asset-card" key={file.path}>
                    <Card
                        allowsSelection={false}
                        key={file.path}
                        actionMenu={
                            <DropdownButton onSelect={e => removeFile(e, file.path)}>
                                <MenuItem>Remove</MenuItem>
                            </DropdownButton>
                        }>
                        <CardPreview>
                            <ModalTrigger>
                                {getPsdAssetThumbnail(file, 'small')}
                                <Dialog title={file.name} confirmLabel="OK">
                                    <center>
                                        <div>
                                            {getPsdAssetThumbnail(file, 'large')}
                                        </div>
                                        {getDocInfo(file)}
                                    </center>
                                </Dialog>
                            </ModalTrigger>
                        </CardPreview>
                        { getCardBody(file) }
                        { getCardFooter(file) }
                    </Card>
                </div>
            } else {
                return <div className="adobe-skycity-asset-card" key={file.path}>
                    <Card
                        allowsSelection={false}
                        key={file.path}
                        actionMenu={
                            <DropdownButton onSelect={e => removeFile(e, file.path)}>
                                <MenuItem>Remove</MenuItem>
                            </DropdownButton>
                        }>
                        <CardPreview>
                            <ModalTrigger>
                                <Asset
                                    type="image"
                                    cache={true}
                                    src={file.smallRenditionGet} />
                                <Dialog title={file.name} confirmLabel="OK">
                                    <center>
                                        <div>
                                            <Asset
                                                className="adobe-skycity-cursor-pointer"
                                                type="image"
                                                cache={true}
                                                src={file.largeRenditionGet} />
                                        </div>
                                        {getDocInfo(file)}
                                    </center>
                                </Dialog>
                            </ModalTrigger>
                        </CardPreview>
                        { getCardBody(file) }
                        { getCardFooter(file) }
                    </Card>
                </div>
            }
        })
        return cards
    }

    function getFileType (file) {
        return file.type || file.content_type
    }

    function getThumbnails (files, opacity, type) {
        const regex = /(text\/plain|xmp)/ig
        if (files && files[0]) {
            if (files[0].s3_id) {
                return <div className="adobe-skycity-thumbnail-container">
                    { getThumbnail(files, type) }
                </div>
            } else if ((files[0].type && files[0].type.match(regex)) || (files[0].content_type && files[0].content_type.match(regex))) {
                return <SortableContent actionType={actionType} files={files}/>
            } else {
                return <div className="adobe-skycity-thumbnail-container">
                    { getThumbnail(files, type) }
                </div>
            }
        }
        return null
    }

    function SelectInputFile () {
        try {
            if (wait && file && file.name) {
                return <SelectInputProvider>
                    <div className="spectrum-Dropzone">
                        <IllustratedMessage
                            className="adobe-skycity-half-opacity"
                            heading={title} /><br /><br />
                        <SkycityProgress upprogress={file.progress} caption={`${__('uploading')} ${file.name}\u2026`} />
                        { getThumbnails(files, 'adobe-skycity-half-opacity') }
                    </div>
                </SelectInputProvider>
            } else if (files && files.length !== 0) {
                return <SelectInputProvider>
                    <div className="spectrum-Dropzone">
                        <IllustratedMessage
                            heading={title} /><br /><br />
                        <div className="adobe-skycity-flex-row-space-between">
                            { getThumbnails(files, 'adobe-skycity-full-opacity') }
                            { previewEdit ? getThumbnails(pFiles, 'adobe-skycity-full-opacity', 'path') : null }
                        </div>
                        <input type="file"
                            name="file"
                            id={`fileUpload-${actionType}`}
                            className="adobe-skycity-hide"
                            accept={ getImageTypes(imageTypes) }
                            onChange={ e => clickUpload(e) }
                            multiple={multiple} />
                        { multiple && <div>
                            <div className="adobe-skycity-input-file-number"><Heading variant="subtitle3">{`${files.length} file(s) Selected`}</Heading></div>
                            <div className="adobe-skycity-icon-upload-more">
                                <Button variant="tool" icon={<UploadToCloud size="S"/>} onClick={ e => upload(e) } />
                                { storage && storage.find(st => st === 'cc') &&
                                <Button variant="tool" icon={<CreativeCloud size="S"/>} onClick={ () => browseCc() } />
                                }
                                { storage && storage.find(st => st === 'as') &&
                                <Button variant="tool" icon={<img src={AdobeStockLogo} width="20px" alt="AdobeStockLogo"/>} onClick={ () => browseStock() } />
                                }
                                { storage && storage.find(st => st === 's3') &&
                                <Button variant="tool" icon={<img src={AwsS3Logo} width="20px" alt="AwsS3Logo"/>} onClick={ () => browseS3() } />
                                }
                                <Button variant="tool" icon={<Delete size="S"/>} onClick={ e => deleteAll(e) } />
                            </div>
                        </div>}
                        { !multiple && <div className="adobe-skycity-icon-upload-more">
                            <Button variant="tool" icon={<Delete size="S"/>} onClick={ e => deleteAll(e) } />
                        </div>}
                        { storage && storage.find(st => st === 'cc') &&
                            <CcBrowser
                                title={title}
                                multiple={multiple}
                                showCcBrowserPane={showCcBrowserPane}
                                showCcBrowserPaneFont={showCcBrowserPaneFont}
                                showCcBrowserPaneSecond={showCcBrowserPaneSecond}
                                showCcBrowserPaneIcc={showCcBrowserPaneIcc}
                                imageTypes={imageTypes.split(',')}
                                inputType={inputType}
                                actionType={actionType}
                                documentManifest={documentManifest} />
                        }
                        { storage && storage.find(st => st === 'as') &&
                            <StockBrowser
                                title={title}
                                multiple={multiple}
                                showAsBrowserPane={showAsBrowserPane}
                                showAsBrowserPaneSecond={showAsBrowserPaneSecond}
                                showAsBrowserPaneIcc={showAsBrowserPaneIcc}
                                imageTypes={imageTypes.split(',')}
                                inputType={inputType}
                                actionType={actionType}
                                documentManifest={documentManifest} />
                        }
                        { storage && storage.find(st => st === 's3') &&
                            <S3Browser
                                title={title}
                                multiple={multiple}
                                showS3BrowserPane={showS3BrowserPane}
                                showS3BrowserPaneSecond={showS3BrowserPaneSecond}
                                showS3BrowserPaneFont={showS3BrowserPaneFont}
                                showS3BrowserPaneIcc={showS3BrowserPaneIcc}
                                imageTypes={imageTypes.split(',')}
                                inputType={inputType}
                                actionType={actionType}
                                documentManifest={documentManifest} />
                        }
                    </div>
                </SelectInputProvider>
            } else {
                // console.log(`ccBrowserAllowsMultipleSelection: ${ccBrowserAllowsMultipleSelection}`);
                // console.log(`imageTypes: ${imageTypes}`);
                return <SelectInputProvider>
                    <div>
                        <DropZone
                            onDrop={ e => clickUpload(e) }
                            onDragOver={ e => onDragOver(e) }>
                            <IllustratedMessage
                                heading={title} /><br /><br />
                            <div className="adobe-skycity-dropzone">
                                <IllustratedMessage
                                    heading=""
                                    description={__('select_input_files_title_upload')}
                                    illustration={<UploadToCloud size="L"/>}
                                    onClick={ e => upload(e) } />
                                { storage && storage.find(st => st === 'cc') &&
                                    <IllustratedMessage
                                        heading=""
                                        description={__('select_input_files_title_cc')}
                                        illustration={<CreativeCloud size="L"/>}
                                        onClick={ e => browseCc(e) } />
                                }
                                { storage && storage.find(st => st === 'cloudContent') &&
                                    <IllustratedMessage
                                        heading=""
                                        description={__('select_input_files_title_cloud_content')}
                                        illustration={<Cloud size="L"/>}
                                        onClick={ e => browseCloudContent(e) } />
                                }
                                { storage && storage.find(st => st === 'as') &&
                                    <IllustratedMessage
                                        heading=""
                                        description={__('select_input_files_title_stock')}
                                        illustration={<img src={AdobeStockLogo} alt="AdobeStockLogo"/>}
                                        onClick={ e => browseStock(e) } />
                                }
                                { storage && storage.find(st => st === 's3') &&
                                    <IllustratedMessage
                                        heading=""
                                        description={__('select_input_files_title_s3')}
                                        illustration={<img src={AwsS3Logo} alt="AwsS3Logo"/>}
                                        onClick={ e => browseS3(e) } />
                                }
                            </div>
                            <input type="file"
                                name="file"
                                id={`fileUpload-${actionType}`}
                                className="adobe-skycity-hide"
                                accept={ getImageTypes(imageTypes) }
                                onChange={ e => clickUpload(e) }
                                multiple={multiple} />
                        </DropZone>
                        { storage && storage.find(st => st === 'cc') &&
                            <CcBrowser
                                title={title}
                                multiple={multiple}
                                showCcBrowserPane={showCcBrowserPane}
                                showCcBrowserPaneFont={showCcBrowserPaneFont}
                                showCcBrowserPaneSecond={showCcBrowserPaneSecond}
                                showCcBrowserPaneIcc={showCcBrowserPaneIcc}
                                inputType={inputType}
                                imageTypes={imageTypes.split(',')}
                                actionType={actionType}
                                documentManifest={documentManifest} />
                        }
                        { storage && storage.find(st => st === 'cloudContent') &&
                            <CloudContentBrowser
                                title={title}
                                multiple={multiple}
                                showCloudContentBrowserPane={showCloudContentBrowserPane}
                                showCloudContentBrowserPaneSecond={showCloudContentBrowserPaneSecond}
                                showCloudContentBrowserPaneIcc={showCloudContentBrowserPaneIcc}
                                imageTypes={imageTypes.split(',')}
                                actionType={actionType}
                                documentManifest={documentManifest} />
                        }
                        { storage && storage.find(st => st === 'as') &&
                            <StockBrowser
                                title={title}
                                multiple={multiple}
                                showAsBrowserPane={showAsBrowserPane}
                                showAsBrowserPaneSecond={showAsBrowserPaneSecond}
                                showAsBrowserPaneIcc={showAsBrowserPaneIcc}
                                imageTypes={imageTypes.split(',')}
                                inputType={inputType}
                                actionType={actionType}
                                documentManifest={documentManifest} />
                        }
                        { storage && storage.find(st => st === 's3') &&
                            <S3Browser
                                title={title}
                                multiple={multiple}
                                showS3BrowserPane={showS3BrowserPane}
                                showS3BrowserPaneSecond={showS3BrowserPaneSecond}
                                showS3BrowserPaneIcc={showS3BrowserPaneIcc}
                                imageTypes={imageTypes.split(',')}
                                inputType={inputType}
                                actionType={actionType}
                                documentManifest={documentManifest} />
                        }
                    </div>
                </SelectInputProvider>
            }
        } catch (e) {
            console.log(e)
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
        }
    }

    return <SelectInputFile />
}
