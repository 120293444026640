import React, { useContext, useState } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'
import SenseiParams from './SenseiParams'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import { ssApiSyncCall } from '../../lib/ccApi.js'
import { getSenseiHeaders, checkFileType } from '../../lib/skycity.js'

import axios from 'axios'

export default function Operation () {
    const {
        errorOptions,
        dispatch,
        inputFiles
    } = useContext(SkycityContext)

    const [params, setParams] = useState({
        text_input: 'What is this image?',
        temperature: '0.2',
        max_out_tokens: '512'
    })

    function formatParams (rawParams) {
        return {
            text_input: rawParams.text_input,
            temperature: Number.parseFloat(rawParams.temperature),
            max_out_tokens: Number.parseInt(rawParams.max_out_tokens)
        }
    }

    async function start (curFile) {
        const files = []
        //  replace with presignedUrl
        const filePath = curFile.path

        const codePayload = {
            contentAnalyzerRequests: {
                'sensei:name': 'LLaVA',
                'sensei:invocation_mode': 'synchronous',
                'sensei:invocation_batch': false,
                'sensei:in_response': false,
                'sensei:engines': [
                    {
                        'sensei:execution_info': {
                            'sensei:engine': 'Classification:autocrop:Service-2c51b0eb81ab454f84287b403cd7e0f8'
                        },
                        'sensei:params': formatParams(params),
                        'sensei:inputs': {
                            image: {
                                'dc:format': checkFileType(curFile.type),
                                'sensei:repoType': 'HTTP',
                                'repo:path': filePath
                            }
                        },
                        'sensei:outputs': {
                            output: {
                                'dc:format': 'application/json',
                                'sensei:multipart_field_name': 'output'
                            },
                            metadata: {
                                'dc:format': 'application/json',
                                'sensei:multipart_field_name': 'metadata'
                            }
                        }
                    }
                ]
            }
        }

        const formData = new FormData()
        formData.append('contentAnalyzerRequests', JSON.stringify(codePayload.contentAnalyzerRequests))
        const config = {
            file: curFile,
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_MLSENSEI}/predict`,
            method: 'POST',
            mode: 'cors',
            timeout: 90000,
            headers: getSenseiHeaders(),
            payload: codePayload,
            body: {
                formData: formData
            }
        }

        /* *********************************** */
        dispatch({ type: 'set-codes', payload: { config: config } })

        try {
            const responseBody = await ssApiSyncCall(config)
            const response = JSON.parse(responseBody.get('metadata'))
            response.output = JSON.parse(responseBody.get('output'))

            dispatch({
                type: 'skycity-success',
                payload: {
                    files: files,
                    response: response,
                    loading: false,
                    wait: false
                }
            })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            const funcArray = []
            inputFiles.forEach(inputFile => {
                funcArray.push(start(inputFile))
            })

            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    console.log(`err: ${err}`)
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                    dispatch({
                        type: 'sensei-end-wait'
                    })
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <SenseiParams params={params} setParams={setParams} />
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
