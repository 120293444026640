import React, { useContext, useState } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'
import Params from './Params.js'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import axios from 'axios'

import { colligoSyncCall } from '../../lib/ccApi.js'
import {
    getFilename,
    getFilenameWithoutExt,
    getEncodedURI,
    getTempDirectory,
    getColligoHeaders
} from '../../lib/skycity.js'

import {
    exploreIndexDocument,
    exploreHomeDirectory,
    uploadFile,
    retrieveRendition,
    retrievePreSignedDownloadUrl
} from '../../lib/acpApi.js'
const LinkHeader = require('http-link-header')
const uuid = require('uuid/v4')

export default function Operation (files) {
    const {
        inputFiles,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    const [params, setParams] = useState({
        serviceMode: [
            { label: 'Mask', value: 'mask', selected: true },
            { label: 'Cutout', value: 'cutout', selected: false }
        ],
        maskFormat: [
            { label: 'JPEG', value: 'jpeg', selected: true },
            { label: 'PNG', value: 'png', selected: false }
        ]
    })

    function formatParams (params) {
        const mode = params.serviceMode.find((mode) => mode.selected).value
        const outputFormat = params.maskFormat.find((format) => format.selected).value
        return {
            mode: mode,
            output_format: outputFormat
        }
    }

    async function start (curFile) {
        const formattedParams = formatParams(params)
        const codePayload = {
            image: {
                name: 'input_image'
            },
            mode: formattedParams.mode,
            output_format: formattedParams.output_format
        }

        const formData = new FormData()
        // Download image to bytes
        const response = await fetch(curFile.path)
        const inputBlob = await response.blob()
        const fileObject = new File([inputBlob], 'input_image', { type: inputBlob.type })
        formData.append('request', JSON.stringify(codePayload))
        formData.append('input_image', fileObject)

        const config = {
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_MLCOLLIGO}/v1/masking/select-subject`,
            method: 'POST',
            mode: 'cors',
            timeout: 90000,
            headers: getColligoHeaders(),
            payload: codePayload,
            body: {
                formData: formData
            }
        }

        /* *********************************** */
        dispatch({ type: 'set-codes', payload: { config: config } })

        const masksBlob = await colligoSyncCall(config, formattedParams.output_format)
        const outputFileName = `selectsubjectv5_${uuid()}_${curFile.shortName}.${formattedParams.output_format}`
        const newFile = new File([masksBlob], outputFileName, { type: `image/${formattedParams.output_format}` })

        // Upload file object to CC storage
        const outputHref = `${getTempDirectory()}/${outputFileName}`
        // const objects_output = JSON.parse(responseBody.get('objects'))
        // 1. Explore the Index Document and get the Primary URL of the home Directory
        const homeDirectoryPrimaryUrl = await exploreIndexDocument()

        // 2. Explore the home Directory and get the Create URL of the Cloud Documents Directory
        const exploreHomeDirectoryResponse = await exploreHomeDirectory(
            homeDirectoryPrimaryUrl
        )
        const cloudDocumentsCreateUrl = exploreHomeDirectoryResponse.createLink
        const fileUploaded = await uploadFile(
            cloudDocumentsCreateUrl,
            newFile
        )
        const uploadlinks = LinkHeader.parse(fileUploaded.headers.get('link'))
        const primaryUrl = uploadlinks.refs.filter(val => val.rel.match('http://ns.adobe.com/adobecloud/rel/primary'))[0].uri
        const renditionUrl = uploadlinks.refs.filter(val => val.rel.match('http://ns.adobe.com/adobecloud/rel/rendition'))[0].uri
        const largeRendition = await retrieveRendition(
            renditionUrl,
            0,
            `image/${formattedParams.output_format}`
        )
        const preSignedDownloadUrl = await retrievePreSignedDownloadUrl(primaryUrl)
        // Skycity library to be updated for response
        if (files.find(file => file.inputFile === curFile)) {
            return
        }
        console.log(outputHref)
        files.push({
            inputFile: curFile,
            name: getFilename(outputHref),
            shortName: getFilenameWithoutExt(outputHref),
            path: outputHref,
            outputThumbnail: largeRendition,
            output: largeRendition,
            outputToDownload: preSignedDownloadUrl
        })
        console.log(`${getEncodedURI(primaryUrl)}`)

        dispatch({
            type: 'skycity-success',
            payload: {
                files: files,
                response: {},
                loading: false,
                wait: false
            }
        })
    }

    async function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(curFile => {
                funcArray.push(start(curFile))
            })
            await axios.all(funcArray)
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <Params params={params} setParams={setParams} />
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
