import React from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import Provider from '@react/react-spectrum/Provider'
import Button from '@react/react-spectrum/Button'
import { Image } from '@react/react-spectrum/Image'

import NavLink from './NavLink'
import Profile from './Profile'
import CreativeCloudLink from './CreativeCloud'
import TokenLink from './Token'
import Preferences from './Preferences'

import imgExploreApis from '../../asset/skycity.png'
import { goToUrl } from '../../lib/skycity.js'

export default function Header () {
    return (
        <Provider theme="dark" scale="medium" typekitId="wvs4fr">
            <div className="adobe-skycity-page-header">
                <div className="adobe-skycity-header-doclinks">
                    <Button variant="tool"
                        icon={<Image className="adobe-skycity-icon" src={imgExploreApis} alt="Explore CIS Feature"/>}
                        onClick={ (e) => goToUrl(window.location.origin)} />
                    <div>Skycity</div>
                    {/* <NavLink label={__('ps_api_doc')} url={__('ps_api_doc_link')} />
                    <NavLink label={__('lr_api_doc')} url={__('lr_api_doc_link')} />
                    <NavLink label={__('ic_api_doc')} url={__('ic_api_doc_link')} />
                    <NavLink label={__('reflow_api_doc')} url={__('reflow_api_doc_link')} />
                    <NavLink label={__('easybake_link_title')} url={__('easybake_link_prod')} />
                    <NavLink label={__('prerelease_link_title')} url={__('prerelease_link')} /> */}
                </div>
                <div className="adobe-skycity-header-icons">
                    <TokenLink />
                    <Preferences />
                    <CreativeCloudLink />
                    <Profile />
                </div>
            </div>
        </Provider>
    )
}
