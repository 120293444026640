import React, { useContext, useState } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'
import SenseiParams from './SenseiParams'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import { ssApiSyncCall } from '../../lib/ccApi.js'
import {
    getFilename,
    getFilenameWithoutExt,
    getEncodedURI,
    getTempDirectory,
    getSenseiHeaders,
    checkFileType
} from '../../lib/skycity.js'

import axios from 'axios'
import {
    exploreIndexDocument,
    exploreHomeDirectory,
    uploadFile,
    retrieveRendition,
    retrievePreSignedDownloadUrl
} from '../../lib/acpApi.js'
const LinkHeader = require('http-link-header')
const uuid = require('uuid/v4')

export default function Operation () {
    const {
        errorOptions,
        dispatch,
        inputFiles,
        inputSecondFiles
    } = useContext(SkycityContext)

    const [params, setParams] = useState({
        x: '0',
        y: '0'
    })

    function formatParams (rawParams) {
        return {
            x: Number.parseInt(rawParams.x),
            y: Number.parseInt(rawParams.y)
        }
    }

    async function uploadFileToACP (responseBody, partName, cloudDocumentsCreateUrl) {
        const output = responseBody.get(partName)
        const blob = output.slice(0, output.size, 'image/png')

        const outputFileName = `${partName}_${uuid()}.png`
        const outputHref = `${getTempDirectory()}/${outputFileName}`
        const newFile = new File([blob], outputFileName, { type: 'image/png' })
        const fileUploaded = await uploadFile(
            cloudDocumentsCreateUrl,
            newFile
        )
        const uploadlinks = LinkHeader.parse(fileUploaded.headers.get('link'))
        const renditionUrl = uploadlinks.refs.filter(val => val.rel.match('http://ns.adobe.com/adobecloud/rel/rendition'))[0].uri
        const primaryUrl = uploadlinks.refs.filter(val => val.rel.match('http://ns.adobe.com/adobecloud/rel/primary'))[0].uri
        const largeRendition = await retrieveRendition(
            renditionUrl,
            0,
            'image/png'
        )
        const uploadOutput = {
            outputHref: outputHref,
            largeRendition: largeRendition,
            primaryUrl: primaryUrl
        }
        return uploadOutput
    }

    async function start (curFile, secondFile) {
        const files = []
        //  replace with presignedUrl
        const foregroundFilePath = curFile.path
        const backgroundFilePath = secondFile.path

        const codePayload = {
            contentAnalyzerRequests: {
                'sensei:name': 'ParametricHarmonization',
                'sensei:invocation_mode': 'synchronous',
                'sensei:invocation_batch': false,
                'sensei:in_response': false,
                'sensei:engines': [
                    {
                        'sensei:execution_info': {
                            'sensei:engine': 'Classification:autocrop:Service-5fa9480fdd9a4e48a0c3198cbeba9e77'
                        },
                        'sensei:params': formatParams(params),
                        'sensei:inputs': {
                            foreground: {
                                'dc:format': checkFileType(curFile.type),
                                'sensei:repoType': 'HTTP',
                                'repo:path': foregroundFilePath
                            },
                            background: {
                                'dc:format': checkFileType(secondFile.type),
                                'sensei:repoType': 'HTTP',
                                'repo:path': backgroundFilePath
                            }
                        },
                        'sensei:outputs': {
                            composite: {
                                'dc:format': 'image/png',
                                'sensei:multipart_field_name': 'composite'
                            },
                            rgb_curve: {
                                'dc:format': 'image/png',
                                'sensei:multipart_field_name': 'rgb_curve'
                            },
                            shading_map: {
                                'dc:format': 'image/png',
                                'sensei:multipart_field_name': 'shading_map'
                            },
                            metadata: {
                                'dc:format': 'application/json',
                                'sensei:multipart_field_name': 'metadata'
                            }
                        }
                    }
                ]
            }
        }

        const formData = new FormData()
        formData.append('contentAnalyzerRequests', JSON.stringify(codePayload.contentAnalyzerRequests))
        const config = {
            file: curFile,
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_MLSENSEI}/predict`,
            method: 'POST',
            mode: 'cors',
            timeout: 90000,
            headers: getSenseiHeaders(),
            payload: codePayload,
            body: {
                formData: formData
            }
        }

        /* *********************************** */
        dispatch({ type: 'set-codes', payload: { config: config } })

        try {
            const responseBody = await ssApiSyncCall(config)
            const contentAnalyzerResponse = responseBody.get('contentAnalyzerResponse')
            const metadata = JSON.parse(responseBody.get('metadata'))

            responseBody.sensei_metadata = JSON.parse(contentAnalyzerResponse)
            // 1. Explore the Index Document and get the Primary URL of the home Directory
            const homeDirectoryPrimaryUrl = await exploreIndexDocument()
            // 2. Explore the home Directory and get the Create URL of the Cloud Documents Directory
            const exploreHomeDirectoryResponse = await exploreHomeDirectory(
                homeDirectoryPrimaryUrl
            )
            const cloudDocumentsCreateUrl = exploreHomeDirectoryResponse.createLink
            const hrefs = []
            hrefs.push(await uploadFileToACP(responseBody, 'composite', cloudDocumentsCreateUrl))
            hrefs.push(await uploadFileToACP(responseBody, 'rgb_curve', cloudDocumentsCreateUrl))
            hrefs.push(await uploadFileToACP(responseBody, 'shading_map', cloudDocumentsCreateUrl))

            for (const uploadOutput of hrefs) {
                let preSignedDownloadUrl = await retrievePreSignedDownloadUrl(uploadOutput.primaryUrl)
                files.push({
                    inputFile: curFile,
                    name: getFilename(uploadOutput.outputHref),
                    shortName: getFilenameWithoutExt(uploadOutput.outputHref),
                    path: uploadOutput.outputHref,
                    outputThumbnail: uploadOutput.largeRendition,
                    output: uploadOutput.largeRendition,
                    outputToDownload: preSignedDownloadUrl
                })
            }

            dispatch({
                type: 'skycity-success',
                payload: {
                    files: files,
                    response: metadata,
                    loading: false,
                    wait: false
                }
            })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            const funcArray = []
            inputFiles.forEach(inputFile => {
                inputSecondFiles.forEach(inputSecondFile => {
                    funcArray.push(start(inputFile, inputSecondFile))
                })
            })

            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    console.log(`err: ${err}`)
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                    dispatch({
                        type: 'sensei-end-wait'
                    })
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <SenseiParams params={params} setParams={setParams} />
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
